import React, { Component } from 'react'

import Layout from '@components/layout/Layout'
import VideoLibraryComponent from '@components/video-library/video-library.component'
import SiteLink from '@components/SiteLink'
import { pedVideoLists } from '@components/video-library/videos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { win } from '@src/app-utils'

import './patient-caregiver-perspectives.scss'

class PatientCaregiverPerspectives extends Component {
  render() {
    const videoClass = {
      description: ''
    }
    const videoTitleColon = {
      videoTitleColon: ''
    }

    const selectedVideo1 = pedVideoLists.stephanieCindy.find(video => video.selected)
    const selectedVideo2 = pedVideoLists.trinityBeth.find(video => video.selected)
    return (
      <Layout addedClass='page-patient-caregiver-perspectives' location={this.props.location}>
        <div className="patient-caregiver-perspectives">
          <div className="patient-caregiver-header primary-container">
            <h1 className="main-page-header">Patient and Caregiver Perspectives</h1>
            <p className="body-copy-large">
              Listening to pediatric patients and their caregivers describe their experience with narcolepsy may help you
              recognize their symptoms and learn more about the diagnostic challenges that children and adolescents with
              narcolepsy may face.
            </p>
          </div>
          <div className="section-gradient" />
          <div className="patient-caregiver-video-wrapper primary-container">
            {/* TODO Fix Autoplay Tracking */}
            <h2>Select a video to watch a patient and caregiver describe their journey to a narcolepsy diagnosis.</h2>
            <div id="stephanie-cindy" className="patient-caregiver-video-card-wrapper">
              <h4 className="title-stephanie">Stephanie and Cindy</h4>
              <VideoLibraryComponent
                videos={pedVideoLists.stephanieCindy}
                selectedVideo={selectedVideo1}
                videoClass={videoClass}
                videoTitleColon={videoTitleColon}
                scrollToTop={() => this.scrollVideoIntoView('stephanie-cindy')}
                autoPlayVideos={true}
                patientPerspectives={true}
                trackPercentComplete={true}
                location={this.props.location}
              />
            </div>
            <div id="trinity-beth" className="patient-caregiver-video-card-wrapper">
              <h4 className="title-trinity">Trinity and Beth</h4>
              <VideoLibraryComponent
                videos={pedVideoLists.trinityBeth}
                selectedVideo={selectedVideo2}
                videoClass={videoClass}
                videoTitleColon={videoTitleColon}
                scrollToTop={() => this.scrollVideoIntoView('trinity-beth')}
                autoPlayVideos={true}
                patientPerspectives={true}
                trackPercentComplete={true}
                location={this.props.location}
              />
            </div>
            <SiteLink 
              className="cta-tile-button btn-primary"
              to="/resources/resource-center/"
              tracking="patient caregiver perspectives, click, clinical resources - patient caregiver perspectives"
              liveRampTracking={true}
            >
              <div className="cta-tile-button-text">
                Read Next: <span style={{ textTransform: 'none', fontWeight: 'normal' }}>Clinical Resources</span>&nbsp;&nbsp;
              </div>
              <FontAwesomeIcon className="arrow-right" icon="angle-right" />
            </SiteLink>
          </div>
        </div>
      </Layout>
    )
  }

  scrollVideoIntoView(id) {
    if (win && window.innerWidth < 994) {
      let currentVideoPlayer = document.getElementById(id)
      if (currentVideoPlayer && window.pageYOffset > currentVideoPlayer.offsetTop) {
        window.scrollTo(0, currentVideoPlayer.offsetTop - 60)
      }
    }
  }
}

export default PatientCaregiverPerspectives
